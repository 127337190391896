define('cabal-web/components/g-map-address-marker', ['exports', 'ember-g-map/components/g-map-address-marker'], function (exports, _gMapAddressMarker) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gMapAddressMarker.default;
    }
  });
});