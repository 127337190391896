define("cabal-web/constants/ciudades", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = [{
		nombre: "Asuncion",
		codigo: "2307",
		departamento: "Asuncion",
		etiqueta: "Asuncion - Asuncion"
	}, {
		nombre: "Concepcion",
		codigo: "2312",
		departamento: "Concepcion",
		etiqueta: "Concepcion - Concepcion"
	}, {
		nombre: "Belen",
		codigo: "5395",
		departamento: "Concepcion",
		etiqueta: "Belen - Concepcion"
	}, {
		nombre: "Horqueta",
		codigo: "3471",
		departamento: "Concepcion",
		etiqueta: "Horqueta - Concepcion"
	}, {
		nombre: "Loreto",
		codigo: "5379",
		departamento: "Concepcion",
		etiqueta: "Loreto - Concepcion"
	}, {
		nombre: "San Carlos",
		codigo: "9999",
		departamento: "Concepcion",
		etiqueta: "San Carlos - Concepcion"
	}, {
		nombre: "San Lazaro",
		codigo: "6068",
		departamento: "Concepcion",
		etiqueta: "San Lazaro - Concepcion"
	}, {
		nombre: "Yvy Ya'u",
		codigo: "6023",
		departamento: "Concepcion",
		etiqueta: "Yvy Ya'u - Concepcion"
	}, {
		nombre: "Azotey",
		codigo: "6042",
		departamento: "Concepcion",
		etiqueta: "Azotey - Concepcion"
	}, {
		nombre: "Sgto. Jose Felix Lopez",
		codigo: "9999",
		departamento: "Concepcion",
		etiqueta: "Sgto. Jose Felix Lopez - Concepcion"
	}, {
		nombre: "San Alfredo",
		codigo: "9999",
		departamento: "Concepcion",
		etiqueta: "San Alfredo - Concepcion"
	}, {
		nombre: "Paso Barreto",
		codigo: "9999",
		departamento: "Concepcion",
		etiqueta: "Paso Barreto - Concepcion"
	}, {
		nombre: "San Pedro Del Ykuamandiyu",
		codigo: "9999",
		departamento: "San Pedro",
		etiqueta: "San Pedro Del Ykuamandiyu - San Pedro"
	}, {
		nombre: "Antequera",
		codigo: "6054",
		departamento: "San Pedro",
		etiqueta: "Antequera - San Pedro"
	}, {
		nombre: "Chore",
		codigo: "6002",
		departamento: "San Pedro",
		etiqueta: "Chore - San Pedro"
	}, {
		nombre: "General Elizardo Aquino",
		codigo: "3446",
		departamento: "San Pedro",
		etiqueta: "General Elizardo Aquino - San Pedro"
	}, {
		nombre: "Itacurubi Del Rosario",
		codigo: "6027",
		departamento: "San Pedro",
		etiqueta: "Itacurubi Del Rosario - San Pedro"
	}, {
		nombre: "Lima",
		codigo: "6021",
		departamento: "San Pedro",
		etiqueta: "Lima - San Pedro"
	}, {
		nombre: "Nueva Germania",
		codigo: "9999",
		departamento: "San Pedro",
		etiqueta: "Nueva Germania - San Pedro"
	}, {
		nombre: "San Estanislao",
		codigo: "4012",
		departamento: "San Pedro",
		etiqueta: "San Estanislao - San Pedro"
	}, {
		nombre: "San Pablo",
		codigo: "4077",
		departamento: "San Pedro",
		etiqueta: "San Pablo - San Pedro"
	}, {
		nombre: "Tacuati",
		codigo: "9999",
		departamento: "San Pedro",
		etiqueta: "Tacuati - San Pedro"
	}, {
		nombre: "Union",
		codigo: "6025",
		departamento: "San Pedro",
		etiqueta: "Union - San Pedro"
	}, {
		nombre: "25 De Diciembre",
		codigo: "6019",
		departamento: "San Pedro",
		etiqueta: "25 De Diciembre - San Pedro"
	}, {
		nombre: "Villa Del Rosario",
		codigo: "6022",
		departamento: "San Pedro",
		etiqueta: "Villa Del Rosario - San Pedro"
	}, {
		nombre: "General Resquin",
		codigo: "6039",
		departamento: "San Pedro",
		etiqueta: "General Resquin - San Pedro"
	}, {
		nombre: "Yataity Del Norte",
		codigo: "9999",
		departamento: "San Pedro",
		etiqueta: "Yataity Del Norte - San Pedro"
	}, {
		nombre: "Guajayvi",
		codigo: "6040",
		departamento: "San Pedro",
		etiqueta: "Guajayvi - San Pedro"
	}, {
		nombre: "Capiibary",
		codigo: "6012",
		departamento: "San Pedro",
		etiqueta: "Capiibary - San Pedro"
	}, {
		nombre: "Santa Rosa Del Aguaray",
		codigo: "6030",
		departamento: "San Pedro",
		etiqueta: "Santa Rosa Del Aguaray - San Pedro"
	}, {
		nombre: "Yryvu Cua",
		codigo: "9999",
		departamento: "San Pedro",
		etiqueta: "Yryvu Cua - San Pedro"
	}, {
		nombre: "Liberacion",
		codigo: "5652",
		departamento: "San Pedro",
		etiqueta: "Liberacion - San Pedro"
	}, {
		nombre: "Caacupe",
		codigo: "2310",
		departamento: "Cordillera",
		etiqueta: "Caacupe - Cordillera"
	}, {
		nombre: "Altos",
		codigo: "4731",
		departamento: "Cordillera",
		etiqueta: "Altos - Cordillera"
	}, {
		nombre: "Arroyos Y Esteros",
		codigo: "4790",
		departamento: "Cordillera",
		etiqueta: "Arroyos Y Esteros - Cordillera"
	}, {
		nombre: "Atyra",
		codigo: "2670",
		departamento: "Cordillera",
		etiqueta: "Atyra - Cordillera"
	}, {
		nombre: "Caraguatay",
		codigo: "3139",
		departamento: "Cordillera",
		etiqueta: "Caraguatay - Cordillera"
	}, {
		nombre: "Emboscada",
		codigo: "3327",
		departamento: "Cordillera",
		etiqueta: "Emboscada - Cordillera"
	}, {
		nombre: "Eusebio Ayala",
		codigo: "3354",
		departamento: "Cordillera",
		etiqueta: "Eusebio Ayala - Cordillera"
	}, {
		nombre: "Isla Pucu",
		codigo: "3519",
		departamento: "Cordillera",
		etiqueta: "Isla Pucu - Cordillera"
	}, {
		nombre: "Itacurubi De La Cordillera",
		codigo: "3530",
		departamento: "Cordillera",
		etiqueta: "Itacurubi De La Cordillera - Cordillera"
	}, {
		nombre: "Juan De Mena",
		codigo: "6028",
		departamento: "Cordillera",
		etiqueta: "Juan De Mena - Cordillera"
	}, {
		nombre: "Loma Grande",
		codigo: "3646",
		departamento: "Cordillera",
		etiqueta: "Loma Grande - Cordillera"
	}, {
		nombre: "Mbocayaty Del Yhaguy",
		codigo: "9999",
		departamento: "Cordillera",
		etiqueta: "Mbocayaty Del Yhaguy - Cordillera"
	}, {
		nombre: "Nueva Colombia",
		codigo: "3808",
		departamento: "Cordillera",
		etiqueta: "Nueva Colombia - Cordillera"
	}, {
		nombre: "Piribebuy",
		codigo: "3878",
		departamento: "Cordillera",
		etiqueta: "Piribebuy - Cordillera"
	}, {
		nombre: "Primero De Marzo",
		codigo: "4867",
		departamento: "Cordillera",
		etiqueta: "Primero De Marzo - Cordillera"
	}, {
		nombre: "San Bernardino",
		codigo: "4007",
		departamento: "Cordillera",
		etiqueta: "San Bernardino - Cordillera"
	}, {
		nombre: "Santa Elena",
		codigo: "9999",
		departamento: "Cordillera",
		etiqueta: "Santa Elena - Cordillera"
	}, {
		nombre: "Tobati",
		codigo: "4214",
		departamento: "Cordillera",
		etiqueta: "Tobati - Cordillera"
	}, {
		nombre: "Valenzuela",
		codigo: "9999",
		departamento: "Cordillera",
		etiqueta: "Valenzuela - Cordillera"
	}, {
		nombre: "San Jose Obrero",
		codigo: "4030",
		departamento: "Cordillera",
		etiqueta: "San Jose Obrero - Cordillera"
	}, {
		nombre: "Villarrica",
		codigo: "2325",
		departamento: "Guaira",
		etiqueta: "Villarrica - Guaira"
	}, {
		nombre: "Borja",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Borja - Guaira"
	}, {
		nombre: "Mauricio Jose Troche",
		codigo: "6036",
		departamento: "Guaira",
		etiqueta: "Mauricio Jose Troche - Guaira"
	}, {
		nombre: "Coronel Martinez",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Coronel Martinez - Guaira"
	}, {
		nombre: "Felix Perez Cardozo",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Felix Perez Cardozo - Guaira"
	}, {
		nombre: "General Eugenio A. Garay",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "General Eugenio A. Garay - Guaira"
	}, {
		nombre: "Colonia Independencia",
		codigo: "5827",
		departamento: "Guaira",
		etiqueta: "Colonia Independencia - Guaira"
	}, {
		nombre: "Itape",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Itape - Guaira"
	}, {
		nombre: "Iturbe",
		codigo: "3541",
		departamento: "Guaira",
		etiqueta: "Iturbe - Guaira"
	}, {
		nombre: "Jose Fassardi",
		codigo: "6046",
		departamento: "Guaira",
		etiqueta: "Jose Fassardi - Guaira"
	}, {
		nombre: "Mbocayaty",
		codigo: "3745",
		departamento: "Guaira",
		etiqueta: "Mbocayaty - Guaira"
	}, {
		nombre: "Natalicio Talavera",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Natalicio Talavera - Guaira"
	}, {
		nombre: "Ñumi",
		codigo: "6066",
		departamento: "Guaira",
		etiqueta: "Ñumi - Guaira"
	}, {
		nombre: "San Salvador",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "San Salvador - Guaira"
	}, {
		nombre: "Yataity",
		codigo: "6069",
		departamento: "Guaira",
		etiqueta: "Yataity - Guaira"
	}, {
		nombre: "Dr. Bottrell",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Dr. Bottrell - Guaira"
	}, {
		nombre: "Paso Yobai",
		codigo: "3860",
		departamento: "Guaira",
		etiqueta: "Paso Yobai - Guaira"
	}, {
		nombre: "Tebicuary",
		codigo: "6045",
		departamento: "Guaira",
		etiqueta: "Tebicuary - Guaira"
	}, {
		nombre: "Coronel Oviedo",
		codigo: "3211",
		departamento: "Guaira",
		etiqueta: "Coronel Oviedo - Guaira"
	}, {
		nombre: "Caaguazu",
		codigo: "3073",
		departamento: "Guaira",
		etiqueta: "Caaguazu - Guaira"
	}, {
		nombre: "Carayao",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Carayao - Guaira"
	}, {
		nombre: "Cecilio Baez",
		codigo: "6064",
		departamento: "Guaira",
		etiqueta: "Cecilio Baez - Guaira"
	}, {
		nombre: "Santa Rosa Del Mbutuy",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Santa Rosa Del Mbutuy - Guaira"
	}, {
		nombre: "Dr. Juan Manuel Frutos",
		codigo: "6050",
		departamento: "Guaira",
		etiqueta: "Dr. Juan Manuel Frutos - Guaira"
	}, {
		nombre: "Repatriacion",
		codigo: "6067",
		departamento: "Guaira",
		etiqueta: "Repatriacion - Guaira"
	}, {
		nombre: "Nueva Londres",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Nueva Londres - Guaira"
	}, {
		nombre: "San Joaquin",
		codigo: "6053",
		departamento: "Guaira",
		etiqueta: "San Joaquin - Guaira"
	}, {
		nombre: "San Jose De Los Arroyos",
		codigo: "4028",
		departamento: "Guaira",
		etiqueta: "San Jose De Los Arroyos - Guaira"
	}, {
		nombre: "Yhu",
		codigo: "4329",
		departamento: "Guaira",
		etiqueta: "Yhu - Guaira"
	}, {
		nombre: "J Eulogio Estigarribia",
		codigo: "6001",
		departamento: "Guaira",
		etiqueta: "J Eulogio Estigarribia - Guaira"
	}, {
		nombre: "R.i. 3 Corrales",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "R.i. 3 Corrales - Guaira"
	}, {
		nombre: "Raul Arsenio Oviedo",
		codigo: "6059",
		departamento: "Guaira",
		etiqueta: "Raul Arsenio Oviedo - Guaira"
	}, {
		nombre: "Jose Domingo Ocampos",
		codigo: "5828",
		departamento: "Guaira",
		etiqueta: "Jose Domingo Ocampos - Guaira"
	}, {
		nombre: "Mcal. Francisco Solano Lopez",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "Mcal. Francisco Solano Lopez - Guaira"
	}, {
		nombre: "La Pastora",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "La Pastora - Guaira"
	}, {
		nombre: "3 De Febrero",
		codigo: "9999",
		departamento: "Guaira",
		etiqueta: "3 De Febrero - Guaira"
	}, {
		nombre: "Simon Bolivar",
		codigo: "6048",
		departamento: "Guaira",
		etiqueta: "Simon Bolivar - Guaira"
	}, {
		nombre: "Vaqueria",
		codigo: "6047",
		departamento: "Guaira",
		etiqueta: "Vaqueria - Guaira"
	}, {
		nombre: "Tembiapora",
		codigo: "6063",
		departamento: "Guaira",
		etiqueta: "Tembiapora - Guaira"
	}, {
		nombre: "Nueva Toledo",
		codigo: "6070",
		departamento: "Guaira",
		etiqueta: "Nueva Toledo - Guaira"
	}, {
		nombre: "Caazapa",
		codigo: "3077",
		departamento: "Caazapa",
		etiqueta: "Caazapa - Caazapa"
	}, {
		nombre: "Abai",
		codigo: "9999",
		departamento: "Caazapa",
		etiqueta: "Abai - Caazapa"
	}, {
		nombre: "Buena Vista",
		codigo: "9999",
		departamento: "Caazapa",
		etiqueta: "Buena Vista - Caazapa"
	}, {
		nombre: "Moises Bertoni",
		codigo: "9999",
		departamento: "Caazapa",
		etiqueta: "Moises Bertoni - Caazapa"
	}, {
		nombre: "General Higinio Morinigo",
		codigo: "6044",
		departamento: "Caazapa",
		etiqueta: "General Higinio Morinigo - Caazapa"
	}, {
		nombre: "Maciel",
		codigo: "6009",
		departamento: "Caazapa",
		etiqueta: "Maciel - Caazapa"
	}, {
		nombre: "San Juan Nepomuceno",
		codigo: "6006",
		departamento: "Caazapa",
		etiqueta: "San Juan Nepomuceno - Caazapa"
	}, {
		nombre: "Tavai",
		codigo: "9999",
		departamento: "Caazapa",
		etiqueta: "Tavai - Caazapa"
	}, {
		nombre: "Fulgencio Yegros",
		codigo: "6065",
		departamento: "Caazapa",
		etiqueta: "Fulgencio Yegros - Caazapa"
	}, {
		nombre: "Yuty",
		codigo: "6034",
		departamento: "Caazapa",
		etiqueta: "Yuty - Caazapa"
	}, {
		nombre: "3 De Mayo",
		codigo: "2402",
		departamento: "Caazapa",
		etiqueta: "3 De Mayo - Caazapa"
	}, {
		nombre: "Encarnacion",
		codigo: "2314",
		departamento: "Itapua",
		etiqueta: "Encarnacion - Itapua"
	}, {
		nombre: "Bella Vista",
		codigo: "2927",
		departamento: "Itapua",
		etiqueta: "Bella Vista - Itapua"
	}, {
		nombre: "Cambyreta",
		codigo: "6007",
		departamento: "Itapua",
		etiqueta: "Cambyreta - Itapua"
	}, {
		nombre: "Capitan Meza",
		codigo: "3137",
		departamento: "Itapua",
		etiqueta: "Capitan Meza - Itapua"
	}, {
		nombre: "Capitan Miranda",
		codigo: "6052",
		departamento: "Itapua",
		etiqueta: "Capitan Miranda - Itapua"
	}, {
		nombre: "Nueva Alborada",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Nueva Alborada - Itapua"
	}, {
		nombre: "Carmen Del Parana",
		codigo: "6058",
		departamento: "Itapua",
		etiqueta: "Carmen Del Parana - Itapua"
	}, {
		nombre: "Coronel Bogado",
		codigo: "3210",
		departamento: "Itapua",
		etiqueta: "Coronel Bogado - Itapua"
	}, {
		nombre: "Carlos Antonio Lopez",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Carlos Antonio Lopez - Itapua"
	}, {
		nombre: "Natalio",
		codigo: "3793",
		departamento: "Itapua",
		etiqueta: "Natalio - Itapua"
	}, {
		nombre: "Fram",
		codigo: "3441",
		departamento: "Itapua",
		etiqueta: "Fram - Itapua"
	}, {
		nombre: "General Artigas",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "General Artigas - Itapua"
	}, {
		nombre: "General Delgado",
		codigo: "6055",
		departamento: "Itapua",
		etiqueta: "General Delgado - Itapua"
	}, {
		nombre: "Hohenau",
		codigo: "3470",
		departamento: "Itapua",
		etiqueta: "Hohenau - Itapua"
	}, {
		nombre: "Jesus",
		codigo: "6062",
		departamento: "Itapua",
		etiqueta: "Jesus - Itapua"
	}, {
		nombre: "Leandro Oviedo",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Leandro Oviedo - Itapua"
	}, {
		nombre: "Obligado",
		codigo: "3810",
		departamento: "Itapua",
		etiqueta: "Obligado - Itapua"
	}, {
		nombre: "Mayor Otaðo",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Mayor Otaðo - Itapua"
	}, {
		nombre: "San Cosme Y Damian",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "San Cosme Y Damian - Itapua"
	}, {
		nombre: "San Pedro Del Parana",
		codigo: "6026",
		departamento: "Itapua",
		etiqueta: "San Pedro Del Parana - Itapua"
	}, {
		nombre: "San Rafael Del Parana",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "San Rafael Del Parana - Itapua"
	}, {
		nombre: "Trinidad",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Trinidad - Itapua"
	}, {
		nombre: "Edelira",
		codigo: "6035",
		departamento: "Itapua",
		etiqueta: "Edelira - Itapua"
	}, {
		nombre: "Tomas Romero Pereira",
		codigo: "6033",
		departamento: "Itapua",
		etiqueta: "Tomas Romero Pereira - Itapua"
	}, {
		nombre: "Alto Vera",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Alto Vera - Itapua"
	}, {
		nombre: "La Paz",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "La Paz - Itapua"
	}, {
		nombre: "Yatytay",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Yatytay - Itapua"
	}, {
		nombre: "San Juan Del Parana",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "San Juan Del Parana - Itapua"
	}, {
		nombre: "Pirapo",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Pirapo - Itapua"
	}, {
		nombre: "Itapua Poty",
		codigo: "9999",
		departamento: "Itapua",
		etiqueta: "Itapua Poty - Itapua"
	}, {
		nombre: "San Juan Bautista",
		codigo: "4046",
		departamento: "Misiones",
		etiqueta: "San Juan Bautista - Misiones"
	}, {
		nombre: "Ayolas",
		codigo: "2308",
		departamento: "Misiones",
		etiqueta: "Ayolas - Misiones"
	}, {
		nombre: "San Ignacio",
		codigo: "2321",
		departamento: "Misiones",
		etiqueta: "San Ignacio - Misiones"
	}, {
		nombre: "San Miguel",
		codigo: "4072",
		departamento: "Misiones",
		etiqueta: "San Miguel - Misiones"
	}, {
		nombre: "San Patricio",
		codigo: "4087",
		departamento: "Misiones",
		etiqueta: "San Patricio - Misiones"
	}, {
		nombre: "Santa Maria",
		codigo: "4107",
		departamento: "Misiones",
		etiqueta: "Santa Maria - Misiones"
	}, {
		nombre: "Santa Rosa",
		codigo: "4113",
		departamento: "Misiones",
		etiqueta: "Santa Rosa - Misiones"
	}, {
		nombre: "Santiago",
		codigo: "4118",
		departamento: "Misiones",
		etiqueta: "Santiago - Misiones"
	}, {
		nombre: "Villa Florida",
		codigo: "2323",
		departamento: "Misiones",
		etiqueta: "Villa Florida - Misiones"
	}, {
		nombre: "Yabebyry",
		codigo: "4318",
		departamento: "Misiones",
		etiqueta: "Yabebyry - Misiones"
	}, {
		nombre: "Paraguari",
		codigo: "3851",
		departamento: "Paraguari",
		etiqueta: "Paraguari - Paraguari"
	}, {
		nombre: "Acahay",
		codigo: "2479",
		departamento: "Paraguari",
		etiqueta: "Acahay - Paraguari"
	}, {
		nombre: "Caapucu",
		codigo: "3074",
		departamento: "Paraguari",
		etiqueta: "Caapucu - Paraguari"
	}, {
		nombre: "General Bernardino Caballero",
		codigo: "5290",
		departamento: "Paraguari",
		etiqueta: "General Bernardino Caballero - Paraguari"
	}, {
		nombre: "Carapegua",
		codigo: "3140",
		departamento: "Paraguari",
		etiqueta: "Carapegua - Paraguari"
	}, {
		nombre: "Escobar",
		codigo: "9999",
		departamento: "Paraguari",
		etiqueta: "Escobar - Paraguari"
	}, {
		nombre: "La Colmena",
		codigo: "6013",
		departamento: "Paraguari",
		etiqueta: "La Colmena - Paraguari"
	}, {
		nombre: "Mbuyapey",
		codigo: "3754",
		departamento: "Paraguari",
		etiqueta: "Mbuyapey - Paraguari"
	}, {
		nombre: "Pirayu",
		codigo: "3877",
		departamento: "Paraguari",
		etiqueta: "Pirayu - Paraguari"
	}, {
		nombre: "Quiindy",
		codigo: "3906",
		departamento: "Paraguari",
		etiqueta: "Quiindy - Paraguari"
	}, {
		nombre: "Quyquyho",
		codigo: "9999",
		departamento: "Paraguari",
		etiqueta: "Quyquyho - Paraguari"
	}, {
		nombre: "San Roque Gonzalez",
		codigo: "6024",
		departamento: "Paraguari",
		etiqueta: "San Roque Gonzalez - Paraguari"
	}, {
		nombre: "Sapucai",
		codigo: "4124",
		departamento: "Paraguari",
		etiqueta: "Sapucai - Paraguari"
	}, {
		nombre: "Tebicuary-mi",
		codigo: "9999",
		departamento: "Paraguari",
		etiqueta: "Tebicuary-mi - Paraguari"
	}, {
		nombre: "Yaguaron",
		codigo: "4320",
		departamento: "Paraguari",
		etiqueta: "Yaguaron - Paraguari"
	}, {
		nombre: "Ybycui",
		codigo: "4323",
		departamento: "Paraguari",
		etiqueta: "Ybycui - Paraguari"
	}, {
		nombre: "Yvytimi",
		codigo: "6011",
		departamento: "Paraguari",
		etiqueta: "Yvytimi - Paraguari"
	}, {
		nombre: "Ciudad Del Este",
		codigo: "3194",
		departamento: "Alto Parana",
		etiqueta: "Ciudad Del Este - Alto Parana"
	}, {
		nombre: "Presidente Franco",
		codigo: "3863",
		departamento: "Alto Parana",
		etiqueta: "Presidente Franco - Alto Parana"
	}, {
		nombre: "Domingo Martinez De Irala",
		codigo: "6029",
		departamento: "Alto Parana",
		etiqueta: "Domingo Martinez De Irala - Alto Parana"
	}, {
		nombre: "Dr. Juan Leon Mallorquin",
		codigo: "3552",
		departamento: "Alto Parana",
		etiqueta: "Dr. Juan Leon Mallorquin - Alto Parana"
	}, {
		nombre: "Hernandarias",
		codigo: "3461",
		departamento: "Alto Parana",
		etiqueta: "Hernandarias - Alto Parana"
	}, {
		nombre: "Itakyry",
		codigo: "6041",
		departamento: "Alto Parana",
		etiqueta: "Itakyry - Alto Parana"
	}, {
		nombre: "Juan E. O┤leary",
		codigo: "5122",
		departamento: "Alto Parana",
		etiqueta: "Juan E. O┤leary - Alto Parana"
	}, {
		nombre: "Ñacunday",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Ñacunday - Alto Parana"
	}, {
		nombre: "Yguazu",
		codigo: "6051",
		departamento: "Alto Parana",
		etiqueta: "Yguazu - Alto Parana"
	}, {
		nombre: "Los Cedrales",
		codigo: "6008",
		departamento: "Alto Parana",
		etiqueta: "Los Cedrales - Alto Parana"
	}, {
		nombre: "Minga Guazu",
		codigo: "3765",
		departamento: "Alto Parana",
		etiqueta: "Minga Guazu - Alto Parana"
	}, {
		nombre: "San Cristobal",
		codigo: "6057",
		departamento: "Alto Parana",
		etiqueta: "San Cristobal - Alto Parana"
	}, {
		nombre: "Santa Rita",
		codigo: "4112",
		departamento: "Alto Parana",
		etiqueta: "Santa Rita - Alto Parana"
	}, {
		nombre: "Naranjal",
		codigo: "6016",
		departamento: "Alto Parana",
		etiqueta: "Naranjal - Alto Parana"
	}, {
		nombre: "Santa Rosa Del Monday",
		codigo: "6061",
		departamento: "Alto Parana",
		etiqueta: "Santa Rosa Del Monday - Alto Parana"
	}, {
		nombre: "Minga Pora",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Minga Pora - Alto Parana"
	}, {
		nombre: "Mbaracayu",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Mbaracayu - Alto Parana"
	}, {
		nombre: "San Alberto",
		codigo: "6005",
		departamento: "Alto Parana",
		etiqueta: "San Alberto - Alto Parana"
	}, {
		nombre: "Iruña",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Iruña - Alto Parana"
	}, {
		nombre: "Santa Fe Del Parana",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Santa Fe Del Parana - Alto Parana"
	}, {
		nombre: "Tavapy",
		codigo: "6060",
		departamento: "Alto Parana",
		etiqueta: "Tavapy - Alto Parana"
	}, {
		nombre: "Dr. Raul Peña",
		codigo: "9999",
		departamento: "Alto Parana",
		etiqueta: "Dr. Raul Peña - Alto Parana"
	}, {
		nombre: "Aregua",
		codigo: "2514",
		departamento: "Central",
		etiqueta: "Aregua - Central"
	}, {
		nombre: "Capiata",
		codigo: "2311",
		departamento: "Central",
		etiqueta: "Capiata - Central"
	}, {
		nombre: "Fernando De La Mora",
		codigo: "2315",
		departamento: "Central",
		etiqueta: "Fernando De La Mora - Central"
	}, {
		nombre: "Guarambare",
		codigo: "3455",
		departamento: "Central",
		etiqueta: "Guarambare - Central"
	}, {
		nombre: "Ita",
		codigo: "3522",
		departamento: "Central",
		etiqueta: "Ita - Central"
	}, {
		nombre: "Itaugua",
		codigo: "3539",
		departamento: "Central",
		etiqueta: "Itaugua - Central"
	}, {
		nombre: "Lambare",
		codigo: "3592",
		departamento: "Central",
		etiqueta: "Lambare - Central"
	}, {
		nombre: "Limpio",
		codigo: "3638",
		departamento: "Central",
		etiqueta: "Limpio - Central"
	}, {
		nombre: "Luque",
		codigo: "2317",
		departamento: "Central",
		etiqueta: "Luque - Central"
	}, {
		nombre: "Mariano Roque Alonso",
		codigo: "3700",
		departamento: "Central",
		etiqueta: "Mariano Roque Alonso - Central"
	}, {
		nombre: "Nueva Italia",
		codigo: "3809",
		departamento: "Central",
		etiqueta: "Nueva Italia - Central"
	}, {
		nombre: "Ðemby",
		codigo: "3803",
		departamento: "Central",
		etiqueta: "Ðemby - Central"
	}, {
		nombre: "San Antonio",
		codigo: "4001",
		departamento: "Central",
		etiqueta: "San Antonio - Central"
	}, {
		nombre: "San Lorenzo",
		codigo: "2322",
		departamento: "Central",
		etiqueta: "San Lorenzo - Central"
	}, {
		nombre: "Villa Elisa",
		codigo: "4273",
		departamento: "Central",
		etiqueta: "Villa Elisa - Central"
	}, {
		nombre: "Villeta",
		codigo: "4308",
		departamento: "Central",
		etiqueta: "Villeta - Central"
	}, {
		nombre: "Ypacarai",
		codigo: "4336",
		departamento: "Central",
		etiqueta: "Ypacarai - Central"
	}, {
		nombre: "Ypane",
		codigo: "4339",
		departamento: "Central",
		etiqueta: "Ypane - Central"
	}, {
		nombre: "J Augusto Saldivar",
		codigo: "3543",
		departamento: "Central",
		etiqueta: "J Augusto Saldivar - Central"
	}, {
		nombre: "Pilar",
		codigo: "2319",
		departamento: "Ñeembucu",
		etiqueta: "Pilar - Ñeembucu"
	}, {
		nombre: "Alberdi",
		codigo: "2492",
		departamento: "Ñeembucu",
		etiqueta: "Alberdi - Ñeembucu"
	}, {
		nombre: "Cerrito",
		codigo: "3184",
		departamento: "Ñeembucu",
		etiqueta: "Cerrito - Ñeembucu"
	}, {
		nombre: "Desmochados",
		codigo: "3269",
		departamento: "Ñeembucu",
		etiqueta: "Desmochados - Ñeembucu"
	}, {
		nombre: "General Diaz",
		codigo: "3443",
		departamento: "Ñeembucu",
		etiqueta: "General Diaz - Ñeembucu"
	}, {
		nombre: "Guazu Cua",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Guazu Cua - Ñeembucu"
	}, {
		nombre: "Humaita",
		codigo: "3475",
		departamento: "Ñeembucu",
		etiqueta: "Humaita - Ñeembucu"
	}, {
		nombre: "Isla Umbu",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Isla Umbu - Ñeembucu"
	}, {
		nombre: "Los Laureles",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Los Laureles - Ñeembucu"
	}, {
		nombre: "Mayor Martinez",
		codigo: "3744",
		departamento: "Ñeembucu",
		etiqueta: "Mayor Martinez - Ñeembucu"
	}, {
		nombre: "Paso De Patria",
		codigo: "3858",
		departamento: "Ñeembucu",
		etiqueta: "Paso De Patria - Ñeembucu"
	}, {
		nombre: "San Juan Bautista De Ðeembucu",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "San Juan Bautista De Ðeembucu - Ñeembucu"
	}, {
		nombre: "Tacuaras",
		codigo: "4187",
		departamento: "Ñeembucu",
		etiqueta: "Tacuaras - Ñeembucu"
	}, {
		nombre: "Villa Franca",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Villa Franca - Ñeembucu"
	}, {
		nombre: "Villa Oliva",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Villa Oliva - Ñeembucu"
	}, {
		nombre: "Villalbin",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Villalbin - Ñeembucu"
	}, {
		nombre: "Pedro Juan Caballero",
		codigo: "3832",
		departamento: "Ñeembucu",
		etiqueta: "Pedro Juan Caballero - Ñeembucu"
	}, {
		nombre: "Bella Vista",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Bella Vista - Ñeembucu"
	}, {
		nombre: "Capitan Bado",
		codigo: "3252",
		departamento: "Ñeembucu",
		etiqueta: "Capitan Bado - Ñeembucu"
	}, {
		nombre: "Zanja Pyta",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Zanja Pyta - Ñeembucu"
	}, {
		nombre: "Karapa'i",
		codigo: "9999",
		departamento: "Ñeembucu",
		etiqueta: "Karapa'i - Ñeembucu"
	}, {
		nombre: "Saltos Del Guaira",
		codigo: "4587",
		departamento: "Canindeyu",
		etiqueta: "Saltos Del Guaira - Canindeyu"
	}, {
		nombre: "Corpus Christi",
		codigo: "3247",
		departamento: "Canindeyu",
		etiqueta: "Corpus Christi - Canindeyu"
	}, {
		nombre: "Curuguaty",
		codigo: "3258",
		departamento: "Canindeyu",
		etiqueta: "Curuguaty - Canindeyu"
	}, {
		nombre: "Villa Ygatimi",
		codigo: "9999",
		departamento: "Canindeyu",
		etiqueta: "Villa Ygatimi - Canindeyu"
	}, {
		nombre: "Itanara",
		codigo: "9999",
		departamento: "Canindeyu",
		etiqueta: "Itanara - Canindeyu"
	}, {
		nombre: "Ype Jhu",
		codigo: "9999",
		departamento: "Canindeyu",
		etiqueta: "Ype Jhu - Canindeyu"
	}, {
		nombre: "Francisco Caballero Alvarez",
		codigo: "6031",
		departamento: "Canindeyu",
		etiqueta: "Francisco Caballero Alvarez - Canindeyu"
	}, {
		nombre: "Katuete",
		codigo: "6004",
		departamento: "Canindeyu",
		etiqueta: "Katuete - Canindeyu"
	}, {
		nombre: "La Paloma",
		codigo: "6020",
		departamento: "Canindeyu",
		etiqueta: "La Paloma - Canindeyu"
	}, {
		nombre: "Nueva Esperanza",
		codigo: "5833",
		departamento: "Canindeyu",
		etiqueta: "Nueva Esperanza - Canindeyu"
	}, {
		nombre: "Yasy Kañy",
		codigo: "6049",
		departamento: "Canindeyu",
		etiqueta: "Yasy Kañy - Canindeyu"
	}, {
		nombre: "Ybyrarobana",
		codigo: "9999",
		departamento: "Canindeyu",
		etiqueta: "Ybyrarobana - Canindeyu"
	}, {
		nombre: "Yby Pyta",
		codigo: "9999",
		departamento: "Canindeyu",
		etiqueta: "Yby Pyta - Canindeyu"
	}, {
		nombre: "Benjamin Aceval",
		codigo: "2929",
		departamento: "Presidente Hayes",
		etiqueta: "Benjamin Aceval - Presidente Hayes"
	}, {
		nombre: "Puerto Pinasco",
		codigo: "9999",
		departamento: "Presidente Hayes",
		etiqueta: "Puerto Pinasco - Presidente Hayes"
	}, {
		nombre: "Villa Hayes",
		codigo: "4283",
		departamento: "Presidente Hayes",
		etiqueta: "Villa Hayes - Presidente Hayes"
	}, {
		nombre: "Nanawa",
		codigo: "6010",
		departamento: "Presidente Hayes",
		etiqueta: "Nanawa - Presidente Hayes"
	}, {
		nombre: "Jose Falcon",
		codigo: "4536",
		departamento: "Presidente Hayes",
		etiqueta: "Jose Falcon - Presidente Hayes"
	}, {
		nombre: "Tte 1ro Manuel Irala Fernandez",
		codigo: "9999",
		departamento: "Presidente Hayes",
		etiqueta: "Tte 1ro Manuel Irala Fernandez - Presidente Hayes"
	}, {
		nombre: "Tte. Esteban Martinez",
		codigo: "9999",
		departamento: "Presidente Hayes",
		etiqueta: "Tte. Esteban Martinez - Presidente Hayes"
	}, {
		nombre: "Gral Jose Maria Bruguez",
		codigo: "9999",
		departamento: "Presidente Hayes",
		etiqueta: "Gral Jose Maria Bruguez - Presidente Hayes"
	}, {
		nombre: "Mariscal Estigarribia",
		codigo: "5823",
		departamento: "Boqueron",
		etiqueta: "Mariscal Estigarribia - Boqueron"
	}, {
		nombre: "Filadelfia",
		codigo: "3427",
		departamento: "Boqueron",
		etiqueta: "Filadelfia - Boqueron"
	}, {
		nombre: "Loma Plata",
		codigo: "6003",
		departamento: "Boqueron",
		etiqueta: "Loma Plata - Boqueron"
	}, {
		nombre: "Fuerte Olimpo",
		codigo: "6014",
		departamento: "Alto Paraguay",
		etiqueta: "Fuerte Olimpo - Alto Paraguay"
	}, {
		nombre: "Puerto Casado",
		codigo: "3901",
		departamento: "Alto Paraguay",
		etiqueta: "Puerto Casado - Alto Paraguay"
	}, {
		nombre: "Bahia Negra",
		codigo: "9999",
		departamento: "Alto Paraguay",
		etiqueta: "Bahia Negra - Alto Paraguay"
	}, {
		nombre: "Carmelo Peralta",
		codigo: "9999",
		departamento: "Alto Paraguay",
		etiqueta: "Carmelo Peralta - Alto Paraguay"
	}];
});