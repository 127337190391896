define('cabal-web/router', ['exports', 'cabal-web/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('404', { path: '/*wildcard' });
    this.route('institucional', function () {
      this.route('novedades', function () {
        this.route('rebranding');
        this.route('contact-center');
        this.route('card-meeting');
        this.route('capacitacion');
        this.route('sorteo-cabal20');
      });
    });
    this.route('preguntas-frecuentes');
    this.route('entidades');
    this.route('tu-cabal');
    this.route('comercios');
    this.route('servicios-comercios');
    this.route('tutoriales-documentos'); //obarrios se agrega nuevo link a pagina documentos tutoriales
    this.route('promociones'); //obarrios se agrega nuevo link a pagina promociones
    this.route('formularios', function () {
      this.route('adherite');
      this.route('contacto');
      this.route('actualizar-datos');
    });
    this.route('login');
    this.route('adherite');
    this.route('novedades', function () {
      this.route('rebranding');
      this.route('contact-center');
      this.route('card-meeting');
    });
  });

  exports.default = Router;
});