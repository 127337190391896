define('cabal-web/routes/institucional', ['exports', 'fullpage'], function (exports, _fullpage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			// willTransition(){
			// 	if(navigator.userAgent.toLowerCase().indexOf('rv:11')>0){
			//       		window.fullpage_api.destroy('all')
			//     		}
			// }.
			didTransition: function didTransition() {

				window.$('#navbarNav').collapse('hide');
				window.$('html, body').animate({ scrollTop: 0 }, 300);
				this.get('controller').send('desplazar');
			}
		},
		resetController: function resetController(controller, isExiting, transition) {
			if (isExiting) {
				controller.set('section', null);
			}
		}
	});
});