define('cabal-web/components/parallax-image', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var isTouchDevice = navigator.userAgent.match(/(iPhone|iPod|iPad|Android|playbook|silk|BlackBerry|BB10|Windows Phone|Tizen|Bada|webOS|IEMobile|Opera Mini)/);
			window.$('.parallax-image').imageScroll({
				// imageAttribute: isTouchDevice ? 'image-mobile' : 'image',
				touch: isTouchDevice ? true : false,
				// Probar si con esto se ve mejor en los moviles
				// coverRatio: isTouchDevice ? 0.4 : 0.9
				coverRatio: 0.5
			});
			// document.getElementById('formAdherite').onsubmit= function(e){e.preventDefault(); }
		},
		willDestroyElement: function willDestroyElement() {
			window.$('.parallax-image').imageScroll('destroy');
		}
	});
});