define('cabal-web/components/g-map-address-route', ['exports', 'ember-g-map/components/g-map-address-route'], function (exports, _gMapAddressRoute) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _gMapAddressRoute.default;
    }
  });
});