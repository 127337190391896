define('cabal-web/controllers/institucional/novedades/rebranding', ['exports', 'cabal-web/constants/urls', 'cabal-web/constants/entidades-pagadoras', 'cabal-web/constants/localidades', 'cabal-web/constants/rubros'], function (exports, _urls, _entidadesPagadoras, _localidades, _rubros) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    video1: {
      autoplay: 1,
      showinfo: 0
    },
    video2: {
      autoplay: 0,
      showinfo: 0
    }
  });
});