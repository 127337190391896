define('cabal-web/constants/urls', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = {
		'base': '/api/v1/',
		'contacto': 'contacto',
		'adhesion': 'adhesion',
		'actualizacion': 'actualizacion',
		'solicitartc': 'quiero-tc',
		'asociarsecoop': 'quiero-asociarme'
	};
});