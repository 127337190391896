define('cabal-web/components/form-validable', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var forms = document.getElementsByClassName('needs-validation');
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener('submit', function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
          }
          form.classList.add('was-validated');
        }, false);
      });
      this.$('input').each(function (i, item) {
        $(item).keyup(function () {
          if ($(item).val()) {
            $(item).parent().addClass('con-valor');
          } else {
            $(item).parent().removeClass('con-valor');
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {},
    submit: function submit() {
      this.$('form').removeClass('was-validated');
    }
  });
});