define('cabal-web/components/full-page', ['exports', 'fullpage'], function (exports, _fullpage) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		didInsertElement: function didInsertElement() {
			var fp = new _fullpage.default('#fullpage', {
				autoScrolling: true,
				slidesNavigation: true,
				controlArrows: true,
				responsiveWidth: 900,
				licenseKey: 'OPEN-SOURCE-GPLV3-LICENSE',
				slidesNavPosition: 'bottom',
				onLeave: function onLeave(origin, destination, direction) {
					var loadedSection = this;
					var myNav = document.getElementById('navbar');
					if (destination.index == 0) {
						myNav.classList.remove("scrolled");
						$('.flecha-arriba').fadeOut(200);
					} else {
						myNav.classList.add("scrolled");
						$('.flecha-arriba').fadeIn(200);
						$('.flecha-arriba').click(function () {
							window.fullpage_api.moveTo(1);
						});
					}
				},
				afterRender: function afterRender() {
					window.slideIntervalTimer = setInterval(function () {
						window.fullpage_api.moveSlideRight();
					}, 10000);
				}
			});
		},
		willDestroyElement: function willDestroyElement() {
			if (navigator.userAgent.toLowerCase().indexOf('rv:11') > 0 || navigator.userAgent.toLowerCase().indexOf('edge') > 0) {
				location.reload();
			}
			if (window.fullpage_api) window.fullpage_api.destroy('all');
			clearInterval(window.slideIntervalTimer);
		}
	});
});