define('cabal-web/controllers/application', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	console.log(location.pathname);
	exports.default = Ember.Controller.extend({
		/* obarrios se desactivo el modal del inicio
  isShowingModal: location.pathname === '/' ? true : false,
  showVideoModal: false,
  videoId: '53CenW4_Vpw',
  verVideo() {
     this.set('showVideoModal', true);
  },
  actions: {
  	closeModalDialog() {
  		this.set('isShowingModal', false);
  	}
  }*/
	});
});