define('cabal-web/routes/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			didTransition: function didTransition() {
				window.$('#navbarNav').collapse('hide');
				window.$('html, body').animate({ scrollTop: 0 }, 300);
			}
		},
		saludar: function () {
			console.log(' #####   ###   ######    ###   ##           ##      ## ####### ######  ');
			console.log('##      #####  ##   ##  #####  ##           ##      ## ##      ##   ## ');
			console.log('##     ##   ## ######  ##   ## ##           ##   #  ## #####   ######  ');
			console.log('##     ####### ##   ## ####### ##            ## ### ## ##      ##   ## ');
			console.log(' ##### ##   ## ######  ##   ## #######        ##   ##  ####### ######  ');
		}.on('init')
	});
});