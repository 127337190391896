define('cabal-web/routes/preguntas-frecuentes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			didTransition: function didTransition() {
				window.$('html, body').animate({ scrollTop: 0 }, 300);
				window.$('#navbarNav').collapse('hide');
			}
		}
	});
});