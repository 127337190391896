define('cabal-web/routes/login', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend({
		actions: {
			didTransition: function didTransition() {
				window.$('#navbarNav').collapse('hide');
				window.$('html, body').animate({ scrollTop: 0 }, 300);
			}
		},
		links: function links() {
			var links = [{ texto: 'Portal de comercios', enlace: 'https://cabal.coop.py/CommercePortal' }, { texto: 'Epos Comercio', enlace: 'https://cabal.coop.py/WSEPOS-CABAL-USUARIOS' }, { texto: 'Acceso Entidades Cabal', enlace: 'https://cabal.coop.py/AccesoCabalEntidades' }, { texto: 'Portal de estadísticos Cabal', enlace: 'https://cabal.coop.py/cabal-statistics' }];

			return links;
		}
	});
});