define('cabal-web/controllers/formularios/adherite', ['exports', 'cabal-web/constants/urls', 'cabal-web/constants/entidades-pagadoras', 'cabal-web/constants/localidades', 'cabal-web/constants/ciudades', 'cabal-web/constants/rubros'], function (exports, _urls, _entidadesPagadoras, _localidades, _ciudades, _rubros) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		formulario: {
			actividadComercial: '',
			tipoEmpresa: '',
			ruc: '',
			rubro: '',
			nombreFantasia: '',
			localidad: '',
			direccion: '',
			email: '',
			geografica: '',
			representanteNombre: '',
			representanteDocumento: '',
			representanteTelefono: '',
			representanteRuc: '',
			representanteDireccion: '',
			representanteGeografica: '',
			portalTelefono: '',
			modalidad: [],
			productos: [],
			portalUsuario: '',
			portalDocumento: '',
			telefono: '',
			portalEmail: '',
			liquidacionEntidad: '',
			liquidacionCuenta: '',
			liquidacionTitular: '',
			liquidacionTipoCuenta: '',
			liquidacionDocumento: '',
			liquidacionEmail: '',
			fotocopiaCI: '',
			fotocopiaRUC: '',
			constSociedad: '',
			estatutosSociales: '',
			actaUltAsamblea: '',
			poderesAdministracion: ''
		},
		entidadesPagadoras: _entidadesPagadoras.default,
		localidades: _localidades.default,
		ciudades: _ciudades.default,
		rubros: _rubros.default,
		urls: _urls.default,
		enviando: false,
		enviado: false,
		enviadoConError: false,
		mensajeError: '',
		enviadoConExito: false,
		aceptaTerminos: false,
		tipoEmpresas: ['Empresa Unipersonal', 'Sociedad Anónima', 'Sociedad de Responsabilidad Limitada', 'Empresa individual de Responsabilidad Ltda.', 'Cooperativa', 'Club Social', 'Otros'],
		actions: {
			setFile: function setFile(campo, valor) {
				this.set(campo, valor);
			},
			elegirEntidadPagadora: function elegirEntidadPagadora(valor) {
				console.log(valor);
				this.set('formulario.liquidacionEntidad', valor);
			},
			elegirDepartamento: function elegirDepartamento(valor) {
				console.log(valor);
				this.set('formulario.departamento', valor);
			},
			elegirTipoEmpresa: function elegirTipoEmpresa(valor) {
				this.set('formulario.tipoEmpresa', valor);
			},
			elegirLocalidad: function elegirLocalidad(valor, valor2) {
				console.log(valor);
				console.log(valor2);
				this.set('formulario.localidad', valor);
				this.set('formulario.departamento', valor2.departamento);
			},

			elegirModalidad: function elegirModalidad(mod) {
				var formMod = this.get('formulario.modalidad');
				if (this.get('formulario.modalidad').indexOf(mod) == -1) {
					formMod.push(mod);
				} else {
					formMod.splice(formMod.indexOf(mod), 1);
				}
				this.set('formulario.modalidad', formMod);
			},
			elegirProductos: function elegirProductos(mod) {
				var formMod = this.get('formulario.productos');
				if (this.get('formulario.productos').indexOf(mod) == -1) {
					formMod.push(mod);
				} else {
					formMod.splice(formMod.indexOf(mod), 1);
				}
				this.set('formulario.productos', formMod);
			},
			handleDragComercio: function handleDragComercio(lat, lng, results) {
				var concat = lat + ',' + lng;
				this.set('formulario.geografica', concat);
			},
			handleDragRepresentante: function handleDragRepresentante(lat, lng, results) {
				var concat = lat + ',' + lng;
				this.set('formulario.representanteGeografica', concat);
			},
			elegirActComer: function elegirActComer(valor) {
				this.set('formulario.actividadComercial', valor);
			},
			uploadCI: function uploadCI(valor) {
				console.log('uploadCI ', valor);
			},

			enviar: function enviar() {
				var envio = this.get('formulario');
				var pepe = this;
				pepe.set('enviando', true);
				window.$.ajax({
					url: _urls.default['base'] + _urls.default['adhesion'],
					method: 'post',
					data: JSON.stringify(envio),
					cache: false,
					contentType: 'application/json',
					success: function success(retorno) {
						pepe.set('enviando', false);
						pepe.set('enviado', true);
						pepe.set('enviadoConError', false);
						pepe.set('enviadoConExito', true);
					},
					error: function error(retorno) {
						pepe.set('enviando', false);
						pepe.set('enviado', true);
						pepe.set('enviadoConError', true);
						//Se construye el string de error
						var error_selec = [];
						var error_conj = retorno.responseText.split('\r');
						for (var i = error_conj.length - 1; i >= 0; i--) {
							if (error_conj[i].indexOf('[comercios') !== -1) {
								error_selec.push(error_conj[i].replace('[comercios.arg0', '').replace('.', '').replace(']', '').replace('[', '') + ': ' + error_conj[i + 1].replace('[comercios.arg0', '').replace(']', '').replace('[', ''));
							}
						}
						//Se guarda el error en la variable a mostrar
						pepe.set('mensajeError', error_selec);
						pepe.set('enviadoConExito', false);
					}
				});
			}
		}
	});
});