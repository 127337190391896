define('cabal-web/components/file-upload', ['exports', 'cabal-web/constants/urls'], function (exports, _urls) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		file: null,
		url: Ember.computed('path', function () {
			return _urls.default['base'] + this.path;
		}),
		filename: '',
		uploaded: false,
		uploading: false,
		uploaderror: false,
		cardClass: Ember.computed('uploaderror', 'uploaded', function () {
			if (this.uploaded && !this.uploaderror) {
				return 'border-success text-success';
			}
			if (!this.uploaded && this.uploaderror) {
				return 'border-danger text-danger';
			}
		}),
		actions: {
			reset: function reset() {
				this.set('filename', '');
				this.set('uploading', false);
				this.set('uploaderror', false);
				this.set('uploaded', false);
				this.sendAction('setFile', this.formfield, '');
			},
			upload: function upload() {
				var envio = new FormData();
				var pepe = this;

				this.set('filename', this.$('input')[0].files[0].name);

				envio.append('file', this.$('input')[0].files[0]);

				this.set('uploading', true);
				this.set('uploaderror', false);
				this.set('uploaded', false);

				$.ajax({
					url: _urls.default['base'] + 'upload',
					data: envio,
					processData: false,
					contentType: false,
					type: 'POST',
					success: function success(data) {
						pepe.set('uploading', false);
						pepe.set('uploaded', true);
						pepe.set('uploaderror', false);
						console.log(pepe.formfield);
						pepe.sendAction('setFile', pepe.formfield, data);
					},
					error: function error() {
						pepe.set('uploading', false);
						pepe.set('uploaded', false);
						pepe.set('uploaderror', true);
					}
				});
			}
		}
	});
});