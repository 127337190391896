define('cabal-web/controllers/comercios', ['exports', 'cabal-web/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		isSeccion1: true,
		isSeccion2: false,
		isSeccion3: false,
		actions: {
			seccion1: function seccion1() {
				this.set('isSeccion1', true);
				this.set('isSeccion2', false);
				this.set('isSeccion3', false);
			},
			seccion2: function seccion2() {
				this.set('isSeccion1', false);
				this.set('isSeccion2', true);
				this.set('isSeccion3', false);
			},
			seccion3: function seccion3() {
				this.set('isSeccion1', false);
				this.set('isSeccion2', false);
				this.set('isSeccion3', true);
			}
		}
	});
});