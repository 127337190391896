define('cabal-web/controllers/institucional/index', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		directivos: [{ foto: 'jorge_cruz.jpg', cargo: 'Presidente', nombre: 'Lic. Jorge Cruz Roa' }, { foto: 'carlos_peters.jpg', cargo: 'Vicepresidente', nombre: 'Sr. Carlos Peters' }, //falta
		{ foto: 'gustavo_salerno.jpg', cargo: 'Secretario', nombre: 'C.P.N. Gustavo Salerno' }, { foto: 'luis_sanchez.jpg', cargo: 'Tesorero', nombre: 'Ing. Luis Sánchez' }, { foto: 'juan_carlos_picchio.png', cargo: 'Primer Vocal Titular', nombre: 'Sr. Juan Carlos Picchio' }, { foto: 'carlos_romero.jpg', cargo: 'Segundo Vocal Titular', nombre: 'Ing. Agr. Carlos Romero Roa' }, //falta
		{ foto: 'nestor_wassaf.png', cargo: 'Tercer Vocal Titular', nombre: 'Sr. Nestor Wassaf' }],
		junta: [{ foto: 'nery_candia.jpg', cargo: 'Presidente', nombre: 'Lic. Nery Candia Pittoni' }, { foto: 'diego_urso.jpg', cargo: 'Primer Vocal titular', nombre: 'C.P.N. Diego Urso' }, { foto: 'silvio_peralta.jpg', cargo: 'Segundo Vocal Titular', nombre: 'Lic. Silvio Peralta' }, { foto: 'carlos_gonzalez.jpg', cargo: 'Vocal Suplente', nombre: 'Lic. Carlos Gonzalez Cabello' }],
		contactos: [{ foto: 'raul_alvarenga.png', nombre: 'Raúl Alvarenga', area: 'Gerencia General', correo: 'ralvarenga@cabal.com.py', telefono: '(021) 4147-211' }, { foto: 'augusto_cardozo.png', nombre: 'Augusto Cardozo', area: 'Atención a Entidades', correo: 'acardozo@cabal.com.py', telefono: '(021) 4147-111' }, { foto: 'mirian_cabrera.png', nombre: 'Mirian Cabrera', area: 'Atención a Comercios', correo: 'mcabrera@cabal.com.py', telefono: '(021) 4147-150' },
		//{foto: 'juan_cantero.png',nombre:'Juan Cantero',area:'Operaciones y Seguridad',correo:'jcantero@cabal.com.py', telefono: '(021) 4147-170'},
		{ foto: 'hector_martin.png', nombre: 'Héctor Martín', area: 'Administración y Contabilidad', correo: 'hmartin@cabal.com.py', telefono: '(021) 4147-140' }, { foto: 'juan_almada.png', nombre: 'Juan Almada', area: 'Dimo', correo: 'jalmada@cabal.com.py', telefono: '(021) 4147-232' }, { foto: 'cesar_gonzalez.jpg', nombre: 'César Gonzalez', area: 'Atención al Cliente', correo: 'cgonzalez@cabal.com.py', telefono: '(021) 4147-300' }]
	});
});