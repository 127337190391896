define('cabal-web/controllers/formularios/contacto', ['exports', 'cabal-web/constants/urls'], function (exports, _urls) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['areaContacto'],
		areaContacto: '',
		sinArea: Ember.computed('areaContacto', function () {
			if (!this.areaContacto) {
				this.form['area'] = '';
				return true;
			}
		}),
		esComercios: Ember.computed('areaContacto', function () {
			if (this.areaContacto == 'comercios') {
				this.form['area'] = 'Atencion a Comercios';
				return true;
			}
		}),
		esAtencionEntidades: Ember.computed('areaContacto', function () {
			if (this.areaContacto == 'atencionentidades') {
				this.form['area'] = 'Atencion a Entidades';
				return true;
			}
		}),
		esServicioEntidades: Ember.computed('areaContacto', function () {
			if (this.areaContacto == 'servicioentidades') {
				this.form['area'] = 'Servicio a Entidades';
				return true;
			}
		}),
		form: {
			nombres: '',
			apellidos: '',
			telefono: '',
			email: '',
			area: 'Atencion a Comercios',
			mensaje: ''
		},
		enviando: false,
		enviado: false,
		enviadoConError: false,
		mensajeError: '',
		enviadoConExito: false,
		actions: {
			enviar: function enviar() {
				var envio = this.get('form');
				// Enviar por Ajax
				this.set('enviado', false);
				this.set('enviando', true);
				var pepe = this;
				window.$.ajax({
					url: _urls.default['base'] + _urls.default['contacto'],
					method: 'post',
					cache: false,
					contentType: 'application/json',
					data: JSON.stringify(envio),
					success: function success(retorno) {
						console.log(retorno);
						pepe.set('form', {});
						pepe.set('enviando', false);
						pepe.set('enviado', true);
						pepe.set('enviadoConError', false);
						pepe.set('enviadoConExito', true);
					},
					error: function error(retorno) {
						pepe.set('enviando', false);
						pepe.set('enviado', true);
						pepe.set('enviadoConError', true);
						//Se construye el string de error
						var error_selec = [];
						var error_conj = retorno.responseText.split('\r');
						for (var i = error_conj.length - 1; i >= 0; i--) {
							if (error_conj[i].indexOf('[formulario') !== -1) {
								error_selec.push(error_conj[i].replace('[formulario.arg0', '').replace('.', '').replace(']', '').replace('[', '') + ': ' + error_conj[i + 1].replace('[formulario.arg0', '').replace(']', '').replace('[', ''));
							}
						}
						//Se guarda el error en la variable a mostrar
						pepe.set('mensajeError', error_selec);
						pepe.set('enviadoConExito', false);
					}
				});
			}
		}
	});
});