define('cabal-web/controllers/preguntas-frecuentes', ['exports', 'cabal-web/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		preguntasUsuarios: false,
		preguntasComercios: false,
		preguntasEntidades: true,
		actions: {
			seccionUsuarios: function seccionUsuarios() {
				this.set('preguntasUsuarios', true);
				this.set('preguntasComercios', false);
				this.set('preguntasEntidades', false);
			},
			seccionComercios: function seccionComercios() {
				this.set('preguntasUsuarios', false);
				this.set('preguntasComercios', true);
				this.set('preguntasEntidades', false);
			},
			seccionEntidades: function seccionEntidades() {
				this.set('preguntasUsuarios', false);
				this.set('preguntasComercios', false);
				this.set('preguntasEntidades', true);
			}
		}
	});
});