define("cabal-web/constants/localidades", ["exports"], function (exports) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = [{
		"nombre": "Asuncion",
		"ciudades": [{ nombre: "Asuncion", Codigo: "2307", Departamento: "asuncion" }]
	}, {
		"nombre": "Concepcion",
		"ciudades": [{
			nombre: "Concepcion",
			codigo: "2312",
			departamento: "Concepcion"
		}, {
			nombre: "Belen",
			codigo: "5395",
			departamento: "Concepcion"
		}, {
			nombre: "Horqueta",
			codigo: "3471",
			departamento: "Concepcion"
		}, {
			nombre: "Loreto",
			codigo: "5379",
			departamento: "Concepcion"
		}, {
			nombre: "San Carlos",
			codigo: "9999",
			departamento: "Concepcion"
		}, {
			nombre: "San Lazaro",
			codigo: "6068",
			departamento: "Concepcion"
		}, {
			nombre: "Yvy Ya'u",
			codigo: "6023",
			departamento: "Concepcion"
		}, {
			nombre: "Azotey",
			codigo: "6042",
			departamento: "Concepcion"
		}, {
			nombre: "Sgto. Jose Felix Lopez",
			codigo: "9999",
			departamento: "Concepcion"
		}, {
			nombre: "San Alfredo",
			codigo: "9999",
			departamento: "Concepcion"
		}, {
			nombre: "Paso Barreto",
			codigo: "9999",
			departamento: "Concepcion"
		}]
	}, {
		"nombre": "San Pedro",
		"ciudades": [{
			nombre: "San Pedro Del Ykuamandiyu",
			codigo: "9999",
			departamento: "San Pedro"
		}, {
			nombre: "Antequera",
			codigo: "6054",
			departamento: "San Pedro"
		}, {
			nombre: "Chore",
			codigo: "6002",
			departamento: "San Pedro"
		}, {
			nombre: "General Elizardo Aquino",
			codigo: "3446",
			departamento: "San Pedro"
		}, {
			nombre: "Itacurubi Del Rosario",
			codigo: "6027",
			departamento: "San Pedro"
		}, {
			nombre: "Lima",
			codigo: "6021",
			departamento: "San Pedro"
		}, {
			nombre: "Nueva Germania",
			codigo: "9999",
			departamento: "San Pedro"
		}, {
			nombre: "San Estanislao",
			codigo: "4012",
			departamento: "San Pedro"
		}, {
			nombre: "San Pablo",
			codigo: "4077",
			departamento: "San Pedro"
		}, {
			nombre: "Tacuati",
			codigo: "9999",
			departamento: "San Pedro"
		}, {
			nombre: "Union",
			codigo: "6025",
			departamento: "San Pedro"
		}, {
			nombre: "25 De Diciembre",
			codigo: "6019",
			departamento: "San Pedro"
		}, {
			nombre: "Villa Del Rosario",
			codigo: "6022",
			departamento: "San Pedro"
		}, {
			nombre: "General Resquin",
			codigo: "6039",
			departamento: "San Pedro"
		}, {
			nombre: "Yataity Del Norte",
			codigo: "9999",
			departamento: "San Pedro"
		}, {
			nombre: "Guajayvi",
			codigo: "6040",
			departamento: "San Pedro"
		}, {
			nombre: "Capiibary",
			codigo: "6012",
			departamento: "San Pedro"
		}, {
			nombre: "Santa Rosa Del Aguaray",
			codigo: "6030",
			departamento: "San Pedro"
		}, {
			nombre: "Yryvu Cua",
			codigo: "9999",
			departamento: "San Pedro"
		}, {
			nombre: "Liberacion",
			codigo: "5652",
			departamento: "San Pedro"
		}]
	}, {
		"nombre": "Coordillera",
		"ciudades": [{
			nombre: "Caacupe",
			codigo: "2310",
			departamento: "Coordillera"
		}, {
			nombre: "Altos",
			codigo: "4731",
			departamento: "Coordillera"
		}, {
			nombre: "Arroyos Y Esteros",
			codigo: "4790",
			departamento: "Coordillera"
		}, {
			nombre: "Atyra",
			codigo: "2670",
			departamento: "Coordillera"
		}, {
			nombre: "Caraguatay",
			codigo: "3139",
			departamento: "Coordillera"
		}, {
			nombre: "Emboscada",
			codigo: "3327",
			departamento: "Coordillera"
		}, {
			nombre: "Eusebio Ayala",
			codigo: "3354",
			departamento: "Coordillera"
		}, {
			nombre: "Isla Pucu",
			codigo: "3519",
			departamento: "Coordillera"
		}, {
			nombre: "Itacurubi De La Coordillera",
			codigo: "3530",
			departamento: "Coordillera"
		}, {
			nombre: "Juan De Mena",
			codigo: "6028",
			departamento: "Coordillera"
		}, {
			nombre: "Loma Grande",
			codigo: "3646",
			departamento: "Coordillera"
		}, {
			nombre: "Mbocayaty Del Yhaguy",
			codigo: "9999",
			departamento: "Coordillera"
		}, {
			nombre: "Nueva Colombia",
			codigo: "3808",
			departamento: "Coordillera"
		}, {
			nombre: "Piribebuy",
			codigo: "3878",
			departamento: "Coordillera"
		}, {
			nombre: "Primero De Marzo",
			codigo: "4867",
			departamento: "Coordillera"
		}, {
			nombre: "San Bernardino",
			codigo: "4007",
			departamento: "Coordillera"
		}, {
			nombre: "Santa Elena",
			codigo: "9999",
			departamento: "Coordillera"
		}, {
			nombre: "Tobati",
			codigo: "4214",
			departamento: "Coordillera"
		}, {
			nombre: "Valenzuela",
			codigo: "9999",
			departamento: "Coordillera"
		}, {
			nombre: "San Jose Obrero",
			codigo: "4030",
			departamento: "Coordillera"
		}]
	}, {
		"nombre": "Guaira",
		"ciudades": [{
			nombre: "Villarrica",
			codigo: "2325",
			departamento: "Guaira"
		}, {
			nombre: "Borja",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Mauricio Jose Troche",
			codigo: "6036",
			departamento: "Guaira"
		}, {
			nombre: "Coronel Martinez",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Felix Perez Cardozo",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "General Eugenio A. Garay",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Colonia Independencia",
			codigo: "5827",
			departamento: "Guaira"
		}, {
			nombre: "Itape",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Iturbe",
			codigo: "3541",
			departamento: "Guaira"
		}, {
			nombre: "Jose Fassardi",
			codigo: "6046",
			departamento: "Guaira"
		}, {
			nombre: "Mbocayaty",
			codigo: "3745",
			departamento: "Guaira"
		}, {
			nombre: "Natalicio Talavera",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Ñumi",
			codigo: "6066",
			departamento: "Guaira"
		}, {
			nombre: "San Salvador",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Yataity",
			codigo: "6069",
			departamento: "Guaira"
		}, {
			nombre: "Dr. Bottrell",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Paso Yobai",
			codigo: "3860",
			departamento: "Guaira"
		}, {
			nombre: "Tebicuary",
			codigo: "6045",
			departamento: "Guaira"
		}]
	}, {
		"nombre": "CAAGUAZU",
		"ciudades": [{
			nombre: "Coronel Oviedo",
			codigo: "3211",
			departamento: "Guaira"
		}, {
			nombre: "Caaguazu",
			codigo: "3073",
			departamento: "Guaira"
		}, {
			nombre: "Carayao",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Cecilio Baez",
			codigo: "6064",
			departamento: "Guaira"
		}, {
			nombre: "Santa Rosa Del Mbutuy",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Dr. Juan Manuel Frutos",
			codigo: "6050",
			departamento: "Guaira"
		}, {
			nombre: "Repatriacion",
			codigo: "6067",
			departamento: "Guaira"
		}, {
			nombre: "Nueva Londres",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "San Joaquin",
			codigo: "6053",
			departamento: "Guaira"
		}, {
			nombre: "San Jose De Los Arroyos",
			codigo: "4028",
			departamento: "Guaira"
		}, {
			nombre: "Yhu",
			codigo: "4329",
			departamento: "Guaira"
		}, {
			nombre: "J Eulogio Estigarribia",
			codigo: "6001",
			departamento: "Guaira"
		}, {
			nombre: "R.i. 3 Corrales",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Raul Arsenio Oviedo",
			codigo: "6059",
			departamento: "Guaira"
		}, {
			nombre: "Jose Domingo Ocampos",
			codigo: "5828",
			departamento: "Guaira"
		}, {
			nombre: "Mcal. Francisco Solano Lopez",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "La Pastora",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "3 De Febrero",
			codigo: "9999",
			departamento: "Guaira"
		}, {
			nombre: "Simon Bolivar",
			codigo: "6048",
			departamento: "Guaira"
		}, {
			nombre: "Vaqueria",
			codigo: "6047",
			departamento: "Guaira"
		}, {
			nombre: "Tembiapora",
			codigo: "6063",
			departamento: "Guaira"
		}, {
			nombre: "Nueva Toledo",
			codigo: "6070",
			departamento: "Guaira"
		}]
	}, {
		"nombre": "Caazapa",
		"ciudades": [{
			nombre: "Caazapa",
			codigo: "3077",
			departamento: "Caazapa"
		}, {
			nombre: "Abai",
			codigo: "9999",
			departamento: "Caazapa"
		}, {
			nombre: "Buena Vista",
			codigo: "9999",
			departamento: "Caazapa"
		}, {
			nombre: "Moises Bertoni",
			codigo: "9999",
			departamento: "Caazapa"
		}, {
			nombre: "General Higinio Morinigo",
			codigo: "6044",
			departamento: "Caazapa"
		}, {
			nombre: "Maciel",
			codigo: "6009",
			departamento: "Caazapa"
		}, {
			nombre: "San Juan Nepomuceno",
			codigo: "6006",
			departamento: "Caazapa"
		}, {
			nombre: "Tavai",
			codigo: "9999",
			departamento: "Caazapa"
		}, {
			nombre: "Fulgencio Yegros",
			codigo: "6065",
			departamento: "Caazapa"
		}, {
			nombre: "Yuty",
			codigo: "6034",
			departamento: "Caazapa"
		}, {
			nombre: "3 De Mayo",
			codigo: "2402",
			departamento: "Caazapa"
		}]
	}, {
		"nombre": "Itapua",
		"ciudades": [{
			nombre: "Encarnacion",
			codigo: "2314",
			departamento: "Itapua"
		}, {
			nombre: "Bella Vista",
			codigo: "2927",
			departamento: "Itapua"
		}, {
			nombre: "Cambyreta",
			codigo: "6007",
			departamento: "Itapua"
		}, {
			nombre: "Capitan Meza",
			codigo: "3137",
			departamento: "Itapua"
		}, {
			nombre: "Capitan Miranda",
			codigo: "6052",
			departamento: "Itapua"
		}, {
			nombre: "Nueva Alborada",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Carmen Del Parana",
			codigo: "6058",
			departamento: "Itapua"
		}, {
			nombre: "Coronel Bogado",
			codigo: "3210",
			departamento: "Itapua"
		}, {
			nombre: "Carlos Antonio Lopez",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Natalio",
			codigo: "3793",
			departamento: "Itapua"
		}, {
			nombre: "Fram",
			codigo: "3441",
			departamento: "Itapua"
		}, {
			nombre: "General Artigas",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "General Delgado",
			codigo: "6055",
			departamento: "Itapua"
		}, {
			nombre: "Hohenau",
			codigo: "3470",
			departamento: "Itapua"
		}, {
			nombre: "Jesus",
			codigo: "6062",
			departamento: "Itapua"
		}, {
			nombre: "Leandro Oviedo",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Obligado",
			codigo: "3810",
			departamento: "Itapua"
		}, {
			nombre: "Mayor Otaðo",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "San Cosme Y Damian",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "San Pedro Del Parana",
			codigo: "6026",
			departamento: "Itapua"
		}, {
			nombre: "San Rafael Del Parana",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Trinidad",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Edelira",
			codigo: "6035",
			departamento: "Itapua"
		}, {
			nombre: "Tomas Romero Pereira",
			codigo: "6033",
			departamento: "Itapua"
		}, {
			nombre: "Alto Vera",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "La Paz",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Yatytay",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "San Juan Del Parana",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Pirapo",
			codigo: "9999",
			departamento: "Itapua"
		}, {
			nombre: "Itapua Poty",
			codigo: "9999",
			departamento: "Itapua"
		}]
	}, {
		"nombre": "Misiones",
		"ciudades": [{
			nombre: "San Juan Bautista",
			codigo: "4046",
			departamento: "Misiones"
		}, {
			nombre: "Ayolas",
			codigo: "2308",
			departamento: "Misiones"
		}, {
			nombre: "San Ignacio",
			codigo: "2321",
			departamento: "Misiones"
		}, {
			nombre: "San Miguel",
			codigo: "4072",
			departamento: "Misiones"
		}, {
			nombre: "San Patricio",
			codigo: "4087",
			departamento: "Misiones"
		}, {
			nombre: "Santa Maria",
			codigo: "4107",
			departamento: "Misiones"
		}, {
			nombre: "Santa Rosa",
			codigo: "4113",
			departamento: "Misiones"
		}, {
			nombre: "Santiago",
			codigo: "4118",
			departamento: "Misiones"
		}, {
			nombre: "Villa Florida",
			codigo: "2323",
			departamento: "Misiones"
		}, {
			nombre: "Yabebyry",
			codigo: "4318",
			departamento: "Misiones"
		}]
	}, {
		"nombre": "Paraguari",
		"ciudades": [{
			nombre: "Paraguari",
			codigo: "3851",
			departamento: "Paraguari"
		}, {
			nombre: "Acahay",
			codigo: "2479",
			departamento: "Paraguari"
		}, {
			nombre: "Caapucu",
			codigo: "3074",
			departamento: "Paraguari"
		}, {
			nombre: "General Bernardino Caballero",
			codigo: "5290",
			departamento: "Paraguari"
		}, {
			nombre: "Carapegua",
			codigo: "3140",
			departamento: "Paraguari"
		}, {
			nombre: "Escobar",
			codigo: "9999",
			departamento: "Paraguari"
		}, {
			nombre: "La Colmena",
			codigo: "6013",
			departamento: "Paraguari"
		}, {
			nombre: "Mbuyapey",
			codigo: "3754",
			departamento: "Paraguari"
		}, {
			nombre: "Pirayu",
			codigo: "3877",
			departamento: "Paraguari"
		}, {
			nombre: "Quiindy",
			codigo: "3906",
			departamento: "Paraguari"
		}, {
			nombre: "Quyquyho",
			codigo: "9999",
			departamento: "Paraguari"
		}, {
			nombre: "San Roque Gonzalez",
			codigo: "6024",
			departamento: "Paraguari"
		}, {
			nombre: "Sapucai",
			codigo: "4124",
			departamento: "Paraguari"
		}, {
			nombre: "Tebicuary-mi",
			codigo: "9999",
			departamento: "Paraguari"
		}, {
			nombre: "Yaguaron",
			codigo: "4320",
			departamento: "Paraguari"
		}, {
			nombre: "Ybycui",
			codigo: "4323",
			departamento: "Paraguari"
		}, {
			nombre: "Yvytimi",
			codigo: "6011",
			departamento: "Paraguari"
		}]
	}, {
		"nombre": "Alto Parana",
		"ciudades": [{
			nombre: "Ciudad Del Este",
			codigo: "3194",
			departamento: "Alto Parana"
		}, {
			nombre: "Presidente Franco",
			codigo: "3863",
			departamento: "Alto Parana"
		}, {
			nombre: "Domingo Martinez De Irala",
			codigo: "6029",
			departamento: "Alto Parana"
		}, {
			nombre: "Dr. Juan Leon Mallorquin",
			codigo: "3552",
			departamento: "Alto Parana"
		}, {
			nombre: "Hernandarias",
			codigo: "3461",
			departamento: "Alto Parana"
		}, {
			nombre: "Itakyry",
			codigo: "6041",
			departamento: "Alto Parana"
		}, {
			nombre: "Juan E. O┤leary",
			codigo: "5122",
			departamento: "Alto Parana"
		}, {
			nombre: "Ñacunday",
			codigo: "9999",
			departamento: "Alto Parana"
		}, {
			nombre: "Yguazu",
			codigo: "6051",
			departamento: "Alto Parana"
		}, {
			nombre: "Los Cedrales",
			codigo: "6008",
			departamento: "Alto Parana"
		}, {
			nombre: "Minga Guazu",
			codigo: "3765",
			departamento: "Alto Parana"
		}, {
			nombre: "San Cristobal",
			codigo: "6057",
			departamento: "Alto Parana"
		}, {
			nombre: "Santa Rita",
			codigo: "4112",
			departamento: "Alto Parana"
		}, {
			nombre: "Naranjal",
			codigo: "6016",
			departamento: "Alto Parana"
		}, {
			nombre: "Santa Rosa Del Monday",
			codigo: "6061",
			departamento: "Alto Parana"
		}, {
			nombre: "Minga Pora",
			codigo: "9999",
			departamento: "Alto Parana"
		}, {
			nombre: "Mbaracayu",
			codigo: "9999",
			departamento: "Alto Parana"
		}, {
			nombre: "San Alberto",
			codigo: "6005",
			departamento: "Alto Parana"
		}, {
			nombre: "Iruña",
			codigo: "9999",
			departamento: "Alto Parana"
		}, {
			nombre: "Santa Fe Del Parana",
			codigo: "9999",
			departamento: "Alto Parana"
		}, {
			nombre: "Tavapy",
			codigo: "6060",
			departamento: "Alto Parana"
		}, {
			nombre: "Dr. Raul Peña",
			codigo: "9999",
			departamento: "Alto Parana"
		}]
	}, {
		"nombre": "Central",
		"ciudades": [{
			nombre: "Aregua",
			codigo: "2514",
			departamento: "Central"
		}, {
			nombre: "Capiata",
			codigo: "2311",
			departamento: "Central"
		}, {
			nombre: "Fernando De La Mora",
			codigo: "2315",
			departamento: "Central"
		}, {
			nombre: "Guarambare",
			codigo: "3455",
			departamento: "Central"
		}, {
			nombre: "Ita",
			codigo: "3522",
			departamento: "Central"
		}, {
			nombre: "Itaugua",
			codigo: "3539",
			departamento: "Central"
		}, {
			nombre: "Lambare",
			codigo: "3592",
			departamento: "Central"
		}, {
			nombre: "Limpio",
			codigo: "3638",
			departamento: "Central"
		}, {
			nombre: "Luque",
			codigo: "2317",
			departamento: "Central"
		}, {
			nombre: "Mariano Roque Alonso",
			codigo: "3700",
			departamento: "Central"
		}, {
			nombre: "Nueva Italia",
			codigo: "3809",
			departamento: "Central"
		}, {
			nombre: "Ðemby",
			codigo: "3803",
			departamento: "Central"
		}, {
			nombre: "San Antonio",
			codigo: "4001",
			departamento: "Central"
		}, {
			nombre: "San Lorenzo",
			codigo: "2322",
			departamento: "Central"
		}, {
			nombre: "Villa Elisa",
			codigo: "4273",
			departamento: "Central"
		}, {
			nombre: "Villeta",
			codigo: "4308",
			departamento: "Central"
		}, {
			nombre: "Ypacarai",
			codigo: "4336",
			departamento: "Central"
		}, {
			nombre: "Ypane",
			codigo: "4339",
			departamento: "Central"
		}, {
			nombre: "J Augusto Saldivar",
			codigo: "3543",
			departamento: "Central"
		}]
	}, {
		"nombre": "Ñeembucu",
		"ciudades": [{
			nombre: "Pilar",
			codigo: "2319",
			departamento: "Ñeembucu"
		}, {
			nombre: "Alberdi",
			codigo: "2492",
			departamento: "Ñeembucu"
		}, {
			nombre: "Cerrito",
			codigo: "3184",
			departamento: "Ñeembucu"
		}, {
			nombre: "Desmochados",
			codigo: "3269",
			departamento: "Ñeembucu"
		}, {
			nombre: "General Diaz",
			codigo: "3443",
			departamento: "Ñeembucu"
		}, {
			nombre: "Guazu Cua",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Humaita",
			codigo: "3475",
			departamento: "Ñeembucu"
		}, {
			nombre: "Isla Umbu",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Los Laureles",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Mayor Martinez",
			codigo: "3744",
			departamento: "Ñeembucu"
		}, {
			nombre: "Paso De Patria",
			codigo: "3858",
			departamento: "Ñeembucu"
		}, {
			nombre: "San Juan Bautista De Ðeembucu",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Tacuaras",
			codigo: "4187",
			departamento: "Ñeembucu"
		}, {
			nombre: "Villa Franca",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Villa Oliva",
			codigo: "9999",
			departamento: "Ñeembucu"
		}, {
			nombre: "Villalbin",
			codigo: "9999",
			departamento: "Ñeembucu"
		}]
	}, {
		"nombre": "Amambay",
		"ciudades": [{
			nombre: "Pedro Juan Caballero",
			codigo: "3832",
			departamento: "Amambay"
		}, {
			nombre: "Bella Vista",
			codigo: "9999",
			departamento: "Amambay"
		}, {
			nombre: "Capitan Bado",
			codigo: "3252",
			departamento: "Amambay"
		}, {
			nombre: "Zanja Pyta",
			codigo: "9999",
			departamento: "Amambay"
		}, {
			nombre: "Karapa'i",
			codigo: "9999",
			departamento: "Amambay"
		}]
	}, {
		"nombre": "Canindeyu",
		"ciudades": [{
			nombre: "Saltos Del Guaira",
			codigo: "4587",
			departamento: "Canindeyu"
		}, {
			nombre: "Corpus Christi",
			codigo: "3247",
			departamento: "Canindeyu"
		}, {
			nombre: "Curuguaty",
			codigo: "3258",
			departamento: "Canindeyu"
		}, {
			nombre: "Villa Ygatimi",
			codigo: "9999",
			departamento: "Canindeyu"
		}, {
			nombre: "Itanara",
			codigo: "9999",
			departamento: "Canindeyu"
		}, {
			nombre: "Ype Jhu",
			codigo: "9999",
			departamento: "Canindeyu"
		}, {
			nombre: "Francisco Caballero Alvarez",
			codigo: "6031",
			departamento: "Canindeyu"
		}, {
			nombre: "Katuete",
			codigo: "6004",
			departamento: "Canindeyu"
		}, {
			nombre: "La Paloma",
			codigo: "6020",
			departamento: "Canindeyu"
		}, {
			nombre: "Nueva Esperanza",
			codigo: "5833",
			departamento: "Canindeyu"
		}, {
			nombre: "Yasy Kañy",
			codigo: "6049",
			departamento: "Canindeyu"
		}, {
			nombre: "Ybyrarobana",
			codigo: "9999",
			departamento: "Canindeyu"
		}, {
			nombre: "Yby Pyta",
			codigo: "9999",
			departamento: "Canindeyu"
		}]
	}, {
		"nombre": "Presidente Hayes",
		"ciudades": [{
			nombre: "Benjamin Aceval",
			codigo: "2929",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Puerto Pinasco",
			codigo: "9999",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Villa Hayes",
			codigo: "4283",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Nanawa",
			codigo: "6010",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Jose Falcon",
			codigo: "4536",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Tte 1ro Manuel Irala Fernandez",
			codigo: "9999",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Tte. Esteban Martinez",
			codigo: "9999",
			departamento: "Presidente Hayes"
		}, {
			nombre: "Gral Jose Maria Bruguez",
			codigo: "9999",
			departamento: "Presidente Hayes"
		}]
	}, {
		"nombre": "Boqueron",
		"ciudades": [{
			nombre: "Mariscal Estigarribia",
			codigo: "5823",
			departamento: "Boqueron"
		}, {
			nombre: "Filadelfia",
			codigo: "3427",
			departamento: "Boqueron"
		}, {
			nombre: "Loma Plata",
			codigo: "6003",
			departamento: "Boqueron"
		}]
	}, {
		"nombre": "Alto Paraguay",
		"ciudades": [{
			nombre: "Fuerte Olimpo",
			codigo: "6014",
			departamento: "Alto Paraguay"
		}, {
			nombre: "Puerto Casado",
			codigo: "3901",
			departamento: "Alto Paraguay"
		}, {
			nombre: "Bahia Negra",
			codigo: "9999",
			departamento: "Alto Paraguay"
		}, {
			nombre: "Carmelo Peralta",
			codigo: "9999",
			departamento: "Alto Paraguay"
		}]
	}];
});