define('cabal-web/controllers/institucional', ['exports', 'cabal-web/config/environment'], function (exports, _environment) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		queryParams: ['section'],
		section: null,
		actions: {
			desplazar: function desplazar() {
				if (this.section == 'novedades') {
					setTimeout(function () {
						$('html, body').animate({
							scrollTop: $('#novedades').offset().top - 100
						}, 0, 'linear');
					}, 0);
				}
			}
		},
		menuActivo: 'institucional',
		directivos: [{ cargo: 'Presidente', nombre: 'Lic. Jorge Cruz Roa' }, { cargo: 'Vicepresidente', nombre: 'Sr. Rubén Vázquez' }, { cargo: 'Secretario', nombre: 'C.P.N. Gustavo Salerno' }, { cargo: 'Tesorero', nombre: 'Ing. Luis Sánchez' }, { cargo: 'Primer Vocal Titular', nombre: 'Sr. Juan Carlos Picchio' }, { cargo: 'Segundo Vocal Titular', nombre: 'Ing. Carlos Romero Roa' }, { cargo: 'Tercer Vocal Titular', nombre: 'Sr. Nestor Wassaf' }, { cargo: 'Primer Vocal Suplente', nombre: 'Sr. Carlos Peters' }, { cargo: 'Segundo Vocal Suplente', nombre: 'Lic. Juan Pablo Esteche' }],
		junta: [{ cargo: 'Presidente', nombre: 'Lic. Nery Candia Pittoni' }, { cargo: 'Primer Vocal titular', nombre: 'C.P.N. Diego Urso' }, { cargo: 'Segundo Vocal Titular', nombre: 'Lic. Sirbelius Benitez' }, { cargo: 'Vocal Suplente', nombre: 'Lic. Herminio Jara' }],
		contactos: [{ nombre: 'Raúl Alvarenga', area: 'Gerencia General', correo: 'ralvarenga@cabal.com.py', telefono: '(021) 4147-211' }, { nombre: 'Augusto Cardozo', area: 'Atención a Entidades', correo: 'acardozo@cabal.com.py', telefono: '(021) 4147-111' }, { nombre: 'Gloria de Casariego', area: 'Atención a Comercios', correo: 'gdecasariego@cabal.com.py', telefono: '(021) 4147-150' }, { nombre: 'Juan Cantero', area: 'Operaciones y Seguridad', correo: 'jcantero@cabal.com.py', telefono: '(021) 4147-170' }, { nombre: 'Héctor Martín', area: 'Administración y Contabilidad', correo: 'hmartin@cabal.com.py', telefono: '(021) 4147-140' }]
	});
});