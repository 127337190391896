define('cabal-web/controllers/login', ['exports', 'cabal-web/config/environment', 'fullpage', 'cabal-web/constants/urls'], function (exports, _environment, _fullpage, _urls) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		actions: {
			solicitarAccesoEst: function solicitarAccesoEst() {
				this.transitionToRoute("contacto");
			},
			solicitarAccesoEpos: function solicitarAccesoEpos() {
				this.transitionToRoute("contacto");
			}
		}
	});
});